import $ from 'jquery'

$(document).on('click', '.schedule-date-link', function(e) {
  e.preventDefault();
  const target = $(this).data('target');

  $('.schedule-date-link').removeClass('active');
  $(this).addClass('active');

  $('.schedule-date-hours').removeClass('active');
  $(target).addClass('active');
})

$(document).on('change', '.schedule-date-radio', function(e) {
  if ($(this).is(':checked')) {
    const column = $(this).closest('.schedule-date-hours');
    const link = $(`[data-target="#${column.attr('id')}"]`);

    if (!link.hasClass('active')) {
      link.click();
    }

    $('.submit-schedule-btn').attr('disabled', false);
  }
});
