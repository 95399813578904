import Typewriter from 'components/typewriter';
import $ from 'jquery';

const words = [
  'reír',
  'llorar',
  'reflexionar',
  'conocerte',
  'pensar'
];

function ready() {
  const container = document.getElementById('about-typewriter');
  if (container) {
    const typewriter = new Typewriter({ words, container });
    typewriter.start();
  }
}

$(document).on('turbolinks:load', ready);
