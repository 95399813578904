class Typewriter {
  constructor({ container, words }) {
    this.words = words;
    this.container = container;
    this.currentWord = 0;
  }

  start() {
    const word = this.words[this.currentWord];
    this.addLetter(0, word);
  }

  addLetter(i, word) {
    const currentText = word.substring(0, i);
    this.container.innerText = currentText;

    if (i < word.length) {
      setTimeout(() => this.addLetter(i + 1, word), 150);
    } else {
      setTimeout(() => this.removeLetter(word.length - 1, word), 800);
    }
  }

  removeLetter(i, word) {
    const currentText = word.substring(0, i);
    this.container.innerText = currentText

    if (i > 0) {
      setTimeout(() => this.removeLetter(i - 1, word), 100);
    } else {
      this.currentWord = (this.currentWord + 1) % this.words.length;
      this.addLetter(0, this.words[this.currentWord]);
    }
  }
}

export default Typewriter
