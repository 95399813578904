import { Modal } from 'bootstrap'

function storeViewedAt() {
  const now = new Date();
  localStorage.setItem('promo_modal_viewed_at', now.toISOString());
}

function shouldShowModal() {
  const storedDate = localStorage.getItem('promo_modal_viewed_at');
  if (!storedDate) {
    return true;
  }

  const now = new Date();
  const elapsed = now - new Date(storedDate);
  const threshold = 24 * 60 * 60 * 1000; // 24 hours

  return elapsed > threshold;
}

function ready() {
  const element = document.querySelector('#promo-modal');

  if (element && shouldShowModal()) {
    const promoModal = new Modal(element);
    promoModal.show();
    storeViewedAt();
  }
}

document.addEventListener('turbolinks:load', ready);
