import $ from 'jquery'

class TermsForm {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  setup() {
    this.checkbox = this.wrapper.find('#accepted_terms');
    this.button = this.wrapper.find('input[type="submit"]')
    this.checkbox.change(this.handleChange.bind(this));
  }

  handleChange() {
    this.button.attr('disabled', !this.checkbox.is(':checked'));
  }
}

function ready() {
  const $wrapper = $('#terms-form');
  if($wrapper.length > 0) {
    const form = new TermsForm($wrapper);
    form.setup();
  }
}

$(document).on('turbolinks:load', ready);
