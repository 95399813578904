class PayPalButtons {
  constructor(container) {
    this.container = container;
  }

  setup() {
    this.renderButtons();
    this.setAuthenticityToken();
  }

  setAuthenticityToken() {
    this.authenticityToken = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
  }

  renderButtons() {
    paypal
      .Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: this.createOrder.bind(this),
        onApprove: this.onApprove.bind(this),
      })
      .render(this.container);
  }

  async createOrder() {
    const response = await this.post("/paypal_orders", {
      order_id: this.container.dataset.orderId,
    });

    if (response.ok) {
      const data = await response.json();
      return data.id;
    } else {
      this.handleError();
      return null;
    }
  }

  async onApprove(data) {
    const response = await this.post(
      `/paypal_orders/${data.orderID}/confirmation`
    );

    if (response.ok) {
      window.location = this.container.dataset.successUrl;
    } else {
      this.handleError();
    }
  }

  handleError() {
    alert("Something went wrong. Please try again.");
  }

  post(url, body) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        ...body,
        authenticity_token: this.authenticityToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  }
}

function ready() {
  const container = document.querySelector("#paypal-button-container");
  if (container) {
    const buttons = new PayPalButtons(container);
    buttons.setup();
  }
}

document.addEventListener("turbolinks:load", ready);
