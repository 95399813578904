import feather from "feather-icons";

class StarRating {
  constructor(input) {
    this.input = input;
    this.handleClick = this.handleClick.bind(this);
  }

  setup() {
    this.setupContainer();
    this.render();
  }

  handleClick(e) {
    this.input.value = e.currentTarget.dataset.value;
    this.render();
  }

  buildStar() {
    const star = document.createElement('i');
    star.dataset.feather = 'star';
    return star;
  }

  buildButton(value) {
    const button = document.createElement('button');

    button.type = 'button';
    button.dataset.value = value;
    button.onclick = this.handleClick;
    button.classList.add('star-rating-btn');
    console.log('buildButton', value, this.input);
    if (this.input.value >= value) {
      button.classList.add('filled');
    }

    button.appendChild(this.buildStar());

    return button;
  }

  setupContainer() {
    this.container = this.buildContainer();
    this.input.parentNode.appendChild(this.container);
  }

  buildContainer() {
    const container = document.createElement('div');
    container.classList.add('star-rating');
    return container;
  }

  render() {
    this.container.innerHTML = '';

    for (var i = 1; i <= 5; i += 1) {
      const button = this.buildButton(i);
      this.container.appendChild(button)
    }

    feather.replace();
  }
}


function ready() {
  const starRatings = document.querySelectorAll('.star-rating-input')

  for (let i = 0; i < starRatings.length; i += 1) {
    const widget = new StarRating(starRatings[i]);
    widget.setup();
  }
}

document.addEventListener('turbolinks:load', ready);
