import $ from 'jquery'

function bindEvents(dropdown) {
  const showEvents = ['mouseover', 'focusin'];
  const hideEvents = ['mouseleave', 'focusout'];

  showEvents.forEach((event) => {
    dropdown.addEventListener(event, (e) => {
      const menu = dropdown.querySelector('.dropdown-menu');
      menu.classList.add('showing');

      setTimeout(() => {
        menu.classList.add('show');
        menu.classList.remove('showing');
      }, 1);
    });
  });

  hideEvents.forEach((event) => {
    dropdown.addEventListener(event, (e) => {
      const menu = dropdown.querySelector('.dropdown-menu');
      menu.classList.add('showing');
      menu.classList.remove('show');
      setTimeout(() => menu.classList.remove('showing'), 300);
    });
  })
}

function ready() {
  const dropdowns = document.querySelectorAll('.navbar-nav .dropdown');
  const showEvents = ['mouseover', 'focusin'];
  const hideEvents = ['mouseleave', 'focusout'];

  dropdowns.forEach(bindEvents);
}

$(document).on('turbolinks:load', ready)

