import $ from 'jquery';

class CheckoutForm {
  constructor(form) {
    this.form = form;
  }

  setup() {
    this.setupStripe();
    this.setupElements();
    this.setupForm();
  }

  setupStripe() {
    this.stripe = Stripe(this.form.data('stripe-key'));
  }

  setupElements() {
    const elements = this.stripe.elements({ locale: 'es' });
    this.card = elements.create("card");
    this.card.mount('#card-element');
    this.card.on("change", this.onCardChange.bind(this))
  }

  setupForm() {
    this.form.on('submit', this.onSubmit.bind(this));
  }

  async onSubmit(e) {
    e.preventDefault();

    this.loading();
    const result = await this.confirmCardPayment();

    if (result.error) {
      this.loaded();
      $('#card-error').text(result.error.message);
    } else {
      this.confirmOrder()
    }
  }

  loading() {
    const submit = this.form.find('#submit');
    submit.attr('disabled', true);
    submit.find('.button-text').addClass('d-none');
    submit.find('.spinner').removeClass('d-none');
  }

  loaded() {
    const submit = this.form.find('#submit');
    submit.attr('disabled', false);
    submit.find('.spinner').addClass('d-none');
    submit.find('.button-text').removeClass('d-none');
  }

  confirmCardPayment() {
    const clientSecret = this.form.data('client-secret');
    const setupFutureUsage = this.form.find('#save-card').is(':checked') ? 'on_session' : null;
    return this.stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: this.card },
      setup_future_usage: setupFutureUsage
    });
  }

  confirmOrder() {
    window.location = this.form.attr('action');
  }

  onCardChange(event) {
    const errorMessage = event.error ? event.error.message : '';

    this.form.find('button').attr('disabled', event.empty);
    $('#card-error').text(errorMessage)
  }
}

function ready() {
  const $form = $('#checkout-form');
  if($form.length > 0) {
    const checkout = new CheckoutForm($form);
    checkout.setup();
  }
}

$(document).on('turbolinks:load', ready);
