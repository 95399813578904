import $ from 'jquery';

$(document).on('click', '#coupon-code-toggle', function(e){
  e.preventDefault();
  const form = $('#coupon-code-form').toggleClass('d-none');
  $('#checkout-btn').toggleClass('d-none');

  if (form.hasClass('d-none')) {
    $(this).text('Aplicar código de descuento');
  } else {
    $(this).text('Cancelar');
  }
});
