import $ from "jquery";
import "slick-carousel";

function ready() {
  $(".reviews-slider").slick({
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
  });
}

$(document).on("turbolinks:load", ready);
