import $ from 'jquery';

class PaymentMethodForm {
  constructor(form) {
    this.form = form;
  }

  setup() {
    this.setupStripe();
    this.setupElements();
    this.setupForm();
  }

  setupStripe() {
    this.stripe = Stripe(this.form.data('stripe-key'));
  }

  setupElements() {
    const elements = this.stripe.elements({ locale: 'es' });
    this.card = elements.create("card");
    this.card.mount('#card-element');
    this.card.on("change", this.onCardChange.bind(this))
  }

  setupForm() {
    this.form.on('submit', this.onSubmit.bind(this));
  }

  async onSubmit(e) {
    e.preventDefault();

    this.loading();
    const result = await this.createPaymentMethod();

    if (result.error) {
      this.loaded();
      $('#card-error').text(result.error.message);
    } else {
      this.submitPaymentMethod(result)
    }
  }

  loading() {
    const submit = this.form.find('#submit');
    submit.attr('disabled', true);
    submit.find('.button-text').addClass('d-none');
    submit.find('.spinner').removeClass('d-none');
  }

  loaded() {
    const submit = this.form.find('#submit');
    submit.attr('disabled', false);
    submit.find('.spinner').addClass('d-none');
    submit.find('.button-text').removeClass('d-none');
  }

  createPaymentMethod() {
    return this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card
    });
  }

  submitPaymentMethod({ paymentMethod }) {
    const formData = new FormData(this.form[0]);
    const $form = $('<form></form>').attr("method", "post").attr("action", this.form.attr('action'));

    this.buildInput('payment_method', paymentMethod.id).appendTo($form);
    this.buildInput('authenticity_token', formData.get('authenticity_token')).appendTo($form);
    if (formData.get('save_card')) {
      this.buildInput('save_card', formData.get('save_card')).appendTo($form);
    }

    $form.insertAfter(this.form);
    $form.trigger('submit');
  }

  buildInput(name, value) {
    return $('<input>').attr('type', 'hidden').attr('name', name).val(value);
  }

  onCardChange(event) {
    const errorMessage = event.error ? event.error.message : '';

    this.form.find('button').attr('disabled', event.empty);
    $('#card-error').text(errorMessage)
  }
}

function ready() {
  const $form = $('#payment-method-form');
  if($form.length > 0) {
    const checkout = new PaymentMethodForm($form);
    checkout.setup();
  }
}

$(document).on('turbolinks:load', ready);
