import "selectize"
import $ from 'jquery'

function ready() {
  $('.selectize').selectize({
    delimiter: ',',
    persist: false,
    create: true
  });
}

$(document).on('turbolinks:load', ready);
