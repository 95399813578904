import $ from "jquery";
import "slick-carousel";

function ready() {
  $(".homepage-slider").slick({
    arrows: true,
    prevArrow: "#homepage-slider-prev",
    nextArrow: "#homepage-slider-next",
    autoplay: true,
    autoplaySpeed: 5000,
  });
}

function unslick() {
  $(".homepage-slider").slick("unslick");
}

$(document).on("turbolinks:before-cache", unslick);
$(document).on("turbolinks:load", ready);
