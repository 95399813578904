// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap";
import "components/browse";
import "components/icons";
import "components/terms-form";
import "components/selectize";
import "components/schedule";
import "components/checkout-form";
import "components/homepage-slider";
import "components/payment-method-form";
import "components/tooltips";
import "components/about-hero";
import "components/reviews-slider";
import "components/therapist-slider";
import "components/navbar";
import "components/promo-modal";
import "components/star-rating-input";
import "components/coupon-code";
import "components/card-picker";
import "components/paypal-buttons";
import "components/appointment-therapist-search";

import "stylesheets/application";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
