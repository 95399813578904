import $ from "jquery";

function ready() {
  const $picker = $("[data-address-zone-picker]");
  let submitTimeout = null;
  const submitForm = () => {
    $picker.closest("form").trigger("submit");
  };

  $picker.selectize({
    onType: () => {
      console.log("type", { submitTimeout });
      clearTimeout(submitTimeout);
    },
    onChange: (value) => {
      if (value === "") {
        submitTimeout = setTimeout(submitForm, 1000);
      } else {
        clearTimeout(submitTimeout);
        submitForm();
      }
    },
  });
}

$(document).on("turbolinks:load", ready);
