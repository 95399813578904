class CardPicker {
  constructor(form) {
    this.form = form;
  }

  setup() {
    this.stripe = Stripe(this.form.dataset.stripeKey);
    this.error = document.querySelector('#card-picker-error');
    this.form.addEventListener('submit', this.onSubmit.bind(this));
  }

  onSubmit(e) {
    e.preventDefault();
    this.error.innerText = '';

    if (this.isValid()) {
      this.submit();
    } else {
      this.error.innerText = 'Por favor selecciona una tarjeta de crédito'
    }
  }

  isValid() {
    const formData = new FormData(this.form);
    return !!formData.get('payment_method');
  }

  async submit() {
    this.loading();
    const result = await this.confirmCardPayment();

    if (result.error) {
      this.loaded();
      this.error.innerText = result.error.message;
    } else {
      window.location = this.form.action;
    }
  }

  confirmCardPayment() {
    const clientSecret = this.form.dataset.clientSecret;
    const formData = new FormData(this.form);

    return this.stripe.confirmCardPayment(clientSecret, {
      payment_method: formData.get('payment_method')
    });
  }

  loading() {
    const submit = this.form.querySelector('#submit');
    submit.disabled = true;
    submit.querySelector('.spinner').classList.remove('d-none');
    submit.querySelector('.button-text').classList.add('d-none');
  }

  loaded() {
    const submit = this.form.querySelector('#submit');
    submit.disabled = false;
    submit.querySelector('.spinner').classList.add('d-none');
    submit.querySelector('.button-text').classList.remove('d-none');
  }
}

function setupForm() {
  const form = document.querySelector('#card-picker-form');
  if (form) {
    const cardPicker = new CardPicker(form);
    cardPicker.setup();
  }
}

function setupSwitching() {
  const addCard = document.querySelector('[data-add-new-card]');
  if (addCard) {
    addCard.addEventListener('click', () => {
      document.querySelector('#card-picker-container').classList.add('d-none');
      document.querySelector('#card-form-container').classList.remove('d-none');
    });
  }

  const useExistingCard = document.querySelector('[data-use-existing-card]');
  if (useExistingCard) {
    useExistingCard.addEventListener('click', () => {
      document.querySelector('#card-picker-container').classList.remove('d-none');
      document.querySelector('#card-form-container').classList.add('d-none');
    });
  }
}

function ready() {
  setupForm()
  setupSwitching();
}

document.addEventListener('turbolinks:load', ready);
