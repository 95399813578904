import $ from 'jquery';
import { Tooltip } from "bootstrap";

function ready () {
  $('[data-bs-toggle="tooltip"]').each(function(){
    new Tooltip(this);
  });
}

$(document).on('turbolinks:load', ready);
